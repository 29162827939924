@tailwind base;
@tailwind components;
@tailwind utilities;

.img_filter {
  filter: invert(1);
}

.header::before {
  content: "";
  position: absolute;
  background: #154c30;
  width: 610px;
  height: 95px;
  border-radius: 0 240px 0 0;
  top: 0;
  z-index: -1;
  box-shadow: 1px -7px 0px 7px #3f8530;
}

.header {
  border-bottom: 2px solid #d7ac44;
  box-shadow: 0px -5px 17px 4px #154c30;
}

.bgimg {
  background-image: url("../src/components/Images/marcha_bg.jpg");
  height: 900px;
  width: 100%;
}

.slider_logo {
  display: flex;
  width: 404px;
  height: 393px;
  border-radius: 430px;
  align-items: center;
  justify-content: center;
  box-shadow: 8px 10px 0px -2px white;
  border: 9px solid cadetblue;
}

.section1 {
  border-radius: 0 190px 190px 0;
}

@keyframes borderAnimation {
  0% {
    border-color: transparent;
    transform: translateX(0);
  }
  50% {
    border-color: #ffd700;
    transform: translateX(10px);
  }
  100% {
    border-color: transparent;
    transform: translateX(0);
  }
}

.welcome {
  text-shadow: 1px 4px 23px #525252;
}
span.anmol_text {
  text-shadow: 4px 1px #939393;
}

.section_slider_box {
  box-shadow: -1px 2px 19px;
}

.product {
  box-shadow: 1px 3px 16px #5db84a;
}

.since_div {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 2px 119px wheat;
  border: 5px dotted green;
  outline: 4px solid ghostwhite;
  z-index: -1;
}

.placeholdercustom::placeholder {
  color: black;
  opacity: 1;
}

.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 30px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0px);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: 400;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;
}

.contact_icon2 {
  background-color: #42db87;
  color: #fff;
  width: 46px;
  height: 44px;
  font-size: 30px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0px);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: 400;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;
}
@keyframes pulsing {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(66, 219, 135, 0.5);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(66, 219, 135, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(66, 219, 135, 0);
  }
}

.contact_on {
  text-shadow: 2px 1px 0px black;
}

.ecard_logo {
  box-shadow: 0px 0px 6px 3px #fff8f1;
  border: 3px solid black;
}

.ecard_shadow {
  box-shadow: 0px 0px 10px 4px #104437;
}

@media only screen and (max-width: 992px) {
  .header::before {
    width: 435px;
    height: 76px;
  }
  .since_div {
    z-index: 1;
  }
}

@media only screen and (max-width: 768px) {
  .slider_logo {
    width: 404px;
    height: 313px;
  }
}

@media only screen and (max-width: 639px) {
  .slider_logo {
    width: 295px;
    height: 291px;
  }

  .since_div {
    z-index: 1;
  }
}
